<template>
  <div style="background: #f3f7f9;overflow-y: auto;height: 100%">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/analysis/staff' }">人员分析</el-breadcrumb-item>
        <el-breadcrumb-item>评估</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px">
      <div style="display: flex">
        <div class="card" style="min-width: 380px;width: 380px">
          <Avatar :src="userData.avatar ? userData.avatar : require('../../../assets/defaultAvatar.svg')" size="65"></Avatar>
          <div class="title" style="margin-top: 10px">姓名：{{ userData.userName }}</div>
          <div class="light" style="margin: 8px 0">所属班级：{{ userData.departmentName }}</div>
          <div class="light">所属专业：{{ userData.postName }}</div>
          <div class="flex" style="margin-top: 20px">
            <div style="display: flex;align-items: center">
              <img :src="require('@/assets/排名@2x.png')" width="28" height="28" style="margin-right: 10px" />
              <div class="light">排名</div>
            </div>
            <div>
              <span class="title">{{ userData.rank }}</span
              ><span class="light"> / {{ userData.rankSize }}</span>
            </div>
          </div>
          <div class="flex" style="margin-top: 20px">
            <div style="display: flex;align-items: center">
              <img :src="require('@/assets/能力掌握@2x.png')" width="28" height="28" style="margin-right: 10px" />
              <div class="light">能力掌握</div>
            </div>
            <div>
              <span class="title">{{ userData.abilityScore }}</span
              ><span class="light"> %</span>
            </div>
          </div>
        </div>
        <div class="card right">
          <span class="title">成绩变化 线性趋势图</span>&nbsp;&nbsp;&nbsp;<span class="light">成绩变化趋势图</span>
          <v-chart autoresize :options="result" style="width: 100%;height: 230px"></v-chart>
        </div>
      </div>
      <div style="margin: 20px 0;display: flex">
        <div class="card" style="width: 380px">
          <div class="title">题型 - 按难度统计条形图</div>
          <div class="light">按难易度统计题型数量的条形图</div>
          <v-chart autoresize :options="type" style="width: 100%;height: 275px"></v-chart>
        </div>
        <div class="card right">
          <div class="flex">
            <div>
              <span class="title">题量统计图</span>&nbsp;&nbsp;&nbsp;<span class="light">总题量：{{ questionCount }}</span>
            </div>
            <DatePicker :value="toMonth" @on-change="changeDate" :clearable="false" type="month" placeholder="请选择日期"></DatePicker>
          </div>
          <v-chart autoresize :options="qNumber" style="width: 100%;height: 290px"></v-chart>
        </div>
      </div>
      <div style="margin-bottom: 20px;display: flex">
        <div class="card" style="width: 640px">
          <div class="title">技能雷达图</div>
          <div class="light">对于用户技能掌握程度雷达图</div>
          <v-chart autoresize :options="ability" style="width: 100%;height: 500px"></v-chart>
        </div>
        <div class="card right">
          <span class="title">按知识点统计柱状图（数量：{{ byK.series[0].data.length }}）</span>&nbsp;&nbsp;&nbsp;<span class="light"
            >按知识点统计题目数量的柱状图</span
          >
          <v-chart autoresize :options="byK" style="width: 100%;height: 270px"></v-chart>
          <div style="height: 1px;background: #F2F3F5;margin-bottom: 15px"></div>
          <span class="title">按题型统计条形图</span>&nbsp;&nbsp;&nbsp;<span class="light">按题型统计题目数量的条形图</span>
          <v-chart autoresize :options="byT" style="width: 100%;height: 240px"></v-chart>
        </div>
      </div>
      <div class="card">
        <div class="flex">
          <div>
            <span class="title">学习统计图</span>&nbsp;&nbsp;&nbsp;<span class="light">按时间统计每日学习视的统计图</span>
            <DatePicker
              @on-clear="changeStudyDate('')"
              style="margin-left: 10px"
              :value="studyMonth"
              @on-change="changeStudyDate"
              type="month"
              placeholder="请选择日期"
            ></DatePicker>
          </div>
          <div class="flex">
            <div class="btn" @click="$router.push({ name: 'staffStatistics', params: { id: userId, type: 'course' } })">
              课程统计
            </div>
            <div class="btn" @click="$router.push({ name: 'staffStatistics', params: { id: userId, type: 'exam' } })">
              考试统计
            </div>
          </div>
        </div>
        <br />
        <Timeline style="margin-top: 20px" v-if="studyData.length !== 0">
          <TimelineItem v-for="(item, index) in studyData" :key="index">
            <div slot="dot" class="dot" @click="selectStudy = index">
              <div>
                <div :class="selectStudy === index ? 'chooseT' : 'title'">
                  {{ $formatTime(item.date, 'yyyy.MM.dd') }}
                </div>
                <div :class="selectStudy === index ? 'chooseTime' : 'time'" style="margin: 16px 0 10px 0;">
                  视频时长:
                  {{ formatSeconds(item.studyDuration) || '— —' }}
                </div>
                <!--              <div :class="selectStudy === index ? 'chooseTime' : 'time'">登录时长: {{formatSeconds(item.stayDuration) || '— —'}}</div>-->
              </div>
              <Radio style="margin-top: 6px" :value="selectStudy === index"></Radio>
            </div>
            <div
              :style="{
                'min-width': cardW + 'px',
                height: item.studies.length > rowNumber && item.showMore ? '100%' : '170px',
                position: 'relative'
              }"
              class="timeCard"
              :class="selectStudy === index ? 'timeCardAct' : ''"
              @click="selectStudy = index"
            >
              <div v-for="(row, rowI) in item.studyRow" :key="rowI" :style="{ 'padding-left': 80 + 'px' }">
                <div
                  style="display: flex"
                  :style="{
                    'flex-direction': rowI % 2 !== 0 ? 'row-reverse' : '',
                    'padding-right': rowI % 2 === 0 ? '' : rowRight - 80 + 'px'
                  }"
                >
                  <div class="timeItem" v-for="(c, i) in row" :key="i">
                    <div style="display: flex;align-items: center;margin-bottom: 12px">
                      <img :src="require(`@assets/评估${c.studyType + 1}@2x.png` || '@assets/评估1@2x.png')" width="13" height="14" />
                      <pre v-if="c.studyType === 0" class="name" :title="c.courseName">                      {{ c.courseName }}</pre>
                      <pre v-if="c.studyType === 1" class="name" :title="c.examName">                      {{ c.examName }}</pre>
                      <pre v-if="c.studyType === 2" class="name" :title="c.practiceName">                      {{ c.practiceName }}</pre>
                      <!--                      <div v-if="c.score" :class="c.score > 59 ? 'scoreG' : 'scoreR'" style="width: 57px">-->
                      <!--                        得分：{{c.score}}-->
                      <!--                      </div>-->
                    </div>
                    <div>
                      <template v-if="c.studyType === 0">
                        <span class="des" style="margin-right: 16px; overflow: hidden">已观看{{ Math.round(c.progress * 100) || 0 }}%</span>
                        <span class="des" style="overflow: hidden" v-if="c.chapterSequence"
                          >观看至第{{ c.chapterSequence }}章第{{ c.sectionSequence }}节</span
                        >
                      </template>
                      <template v-if="c.studyType === 1">
                        <span v-if="c.hasOwnProperty('score')"> 得分：{{ c.score }} </span>
                        <span v-else>考试未提交</span>
                      </template>
                      <template v-if="c.studyType === 2">
                        <span v-if="c.hasOwnProperty('score')"> 得分：{{ c.score }} </span>
                        <span v-else>&nbsp;</span>
                      </template>
                    </div>
                    <div class="timeLine">
                      <div class="lineDot"></div>
                      <div class="line"></div>
                    </div>
                    <!--                <div class="time">{{c.startTime | formatTime('hh:mm')}}&nbsp;&nbsp;&nbsp;{{c.endTime | formatTime('hh:mm')}}</div>-->
                    <div class="time">
                      {{ $formatTime(c.startTime, 'hh:mm') }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="rowI % 2 === 0 && item.showMore && row.length === rowNumber && rowI !== item.studyRow.length - 1"
                  class="circleR"
                  :style="{
                    top: postionTop(rowI, index),
                    left: rowNumber * 200 + 118 + 'px'
                  }"
                ></div>
                <div
                  v-if="rowI % 2 !== 0 && item.showMore && row.length === rowNumber"
                  class="circleL"
                  :style="{ top: postionTop(rowI, index), left: 70 + 'px' }"
                ></div>
              </div>
              <div
                v-if="item.studies.length > rowNumber"
                @click="item.showMore = !item.showMore"
                class="more"
                :style="{
                  'margin-left': selectStudy === index ? '-38px' : '-40px'
                }"
              >
                <img
                  :style="{
                    transform: item.showMore ? 'rotate(180deg)' : 'rotate(0deg)'
                  }"
                  :src="require(selectStudy === index ? '@/assets/arrow2@2x.png' : '@/assets/arrow1@2x.png')"
                  width="13"
                  height="8"
                />
              </div>
            </div>
          </TimelineItem>
        </Timeline>
        <div class="noRes" v-else>
          <img src="../../../assets/暂无记录@2x.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import questionRepo from '../../common/old/questionOld'
export default {
  name: 'Assessment',
  data() {
    return {
      selectStudy: '',
      result: {
        tooltip: {
          formatter: '{c}分'
        },
        grid: {
          top: '16px',
          left: '0',
          right: '0',
          bottom: '5px',
          containLabel: true
        },
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        color: ['#5A8EF9'],
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgb(227, 235, 253)'
                    },
                    {
                      offset: 1,
                      color: 'rgb(248, 250, 255)'
                    }
                  ]
                }
              }
            }
          }
        ]
      },
      type: {
        tooltip: {},
        grid: {
          top: '16px',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['简单', '一般', '困难']
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            data: [
              {
                value: 0,
                itemStyle: {
                  color: '#5A8EF9'
                }
              },
              {
                value: 0,
                itemStyle: {
                  color: '#EFCD67'
                }
              },
              {
                value: 0,
                itemStyle: {
                  color: '#E7674A'
                }
              }
            ],
            type: 'bar'
          }
        ]
      },
      qNumber: {
        tooltip: {
          formatter: '{b0}日: {c0}题'
        },
        grid: {
          top: '16px',
          left: '0',
          right: '10px',
          bottom: '5px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        color: ['#5A8EF9'],
        series: [
          {
            data: [],
            type: 'line',
            smooth: true,
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgb(227, 235, 253)'
                    },
                    {
                      offset: 1,
                      color: 'rgb(248, 250, 255)'
                    }
                  ]
                }
              }
            }
          }
        ]
      },
      ability: {
        grid: {
          top: '16px',
          left: '0',
          right: '0',
          bottom: '0px',
          containLabel: true
        },
        tooltip: {},
        radar: {
          indicator: [{ name: '' }],
          name: {
            textStyle: {
              color: '#000000'
            }
          }
        },
        series: [
          {
            name: '能力雷达图',
            type: 'radar',
            lineStyle: {
              color: '#5A8EF9'
            },
            itemStyle: {
              color: '#5A8EF9'
            },
            areaStyle: {
              color: '#9CBBFB'
            },
            data: [
              {
                value: [],
                name: '能力掌握程度'
              }
            ]
          }
        ]
      },
      byK: {
        color: ['#5A8EF9', '#80D1CD', '#ADD4FF', '#7484A1', '#EECD67'],
        tooltip: {},
        grid: {
          top: '16px',
          left: '0',
          right: '10px',
          bottom: '50px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true,
            interval: 0
          },
          data: []
        },
        dataZoom: [
          {
            type: 'inside',
            endValue: 9,
            minValueSpan: 4,
            maxValueSpan: 19
          },
          {
            type: 'slider',
            showDetail: false
          }
        ],
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            data: []
          }
        ]
      },
      byT: {
        tooltip: {},
        color: ['#5A8EF9', '#80D1CD', '#ADD4FF', '#7484A1', '#EECD67', '#E7674A'],
        grid: {
          top: '16px',
          left: '0',
          right: '10px',
          bottom: '5px',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [
          {
            type: 'bar',
            data: []
          }
        ]
      },
      userId: '',
      questionType: ['判断题', '单选题', '多选题', '填空题', '简答题', '代码题'],
      userData: {},
      questionCount: 0,
      toMonth: this.$moment().format('YYYY-MM'),
      studyMonth: '',
      start: this.$moment()
        .startOf('month')
        .format('x'),
      end: this.$moment()
        .endOf('month')
        .format('x'),
      days: this.$moment().daysInMonth(),
      studyM: '',
      studyData: [],
      rowNumber: 0,
      rowRight: 0,
      cardW: 0
    }
  },
  created() {
    this.userId = this.$handleParams('staffDetailData').userId
  },
  mounted() {
    this.initData()
  },
  methods: {
    postionTop(rowI, index) {
      if (rowI === 0) {
        if (this.selectStudy === index) {
          return 96 + 'px'
        } else {
          return 98 + 'px'
        }
      } else {
        if (this.selectStudy === index) {
          return 96 + 171 * rowI + 'px'
        } else {
          return 98 + 171 * rowI + 'px'
        }
      }
    },
    getCountStatistics() {
      this.qNumber.series[0].data = []
      this.qNumber.xAxis.data = []
      questionRepo.getDaysQuestionCount(this.userId, this.start, this.end).then(res => {
        for (let i = 0; i < this.days; i++) {
          this.qNumber.series[0].data[i] = 0
          this.qNumber.xAxis.data.push(i + 1)
        }
        res.res.forEach(item => {
          this.questionCount = this.questionCount + item.amount
          this.qNumber.series[0].data[Number(item.day.substring(6, 8)) - 1] = item.amount
        })
      })
    },
    initData() {
      questionRepo.getUserAb(this.userId).then(res => {
        this.userData = res.res
      })
      questionRepo.getCountByDifficult(this.userId).then(res => {
        res.res.forEach(item => {
          if (item.difficulty === 0) {
            this.type.series[0].data[0].value = item.amount || 0
          } else if (item.difficulty === 1) {
            this.type.series[0].data[1].value = item.amount || 0
          } else {
            this.type.series[0].data[2].value = item.amount || 0
          }
        })
      })
      questionRepo.getCountByK(this.userId).then(res => {
        res.res.forEach((item, index) => {
          this.byK.xAxis.data.push(item.knowledgePointName || item.knowledgePointId)
          this.byK.series[0].data.push({
            value: item.amount || 0,
            itemStyle: {
              color: this.byK.color[index % 5]
            }
          })
        })
      })
      questionRepo.getCountByT(this.userId).then(res => {
        res.res.forEach((item, index) => {
          this.byT.yAxis.data.push(this.questionType[item.questionType])
          this.byT.series[0].data.push({
            value: item.amount || 0,
            itemStyle: {
              color: this.byT.color[index]
            }
          })
        })
      })
      questionRepo.getAllScore(this.userId).then(res => {
        res.res.forEach(item => {
          this.result.series[0].data.push(item.score)
        })
      })
      questionRepo.getUserAbs(this.userId).then(res => {
        res.res.forEach((item, index) => {
          // if (item.score === 0) {
          //   this.ability.radar.indicator[index] = {
          //     name: item.abilityName,
          //     max: 1
          //   }
          // } else {
          //   this.ability.radar.indicator[index] = {
          //     name: item.abilityName
          //   }
          // }
          this.ability.radar.indicator[index] = {
            name: item.abilityName,
            max: 1
          }
          this.ability.series[0].data[0].value.push(item.score)
        })
      })
      this.getCountStatistics()
      this.getStudy()
    },
    changeDate(val) {
      this.start = this.$moment(val)
        .startOf('month')
        .format('x')
      this.end = this.$moment(val)
        .endOf('month')
        .format('x')
      this.days = this.$moment(val).daysInMonth()
      this.getCountStatistics()
    },
    changeStudyDate(val) {
      if (val) {
        this.studyM = this.$moment(val)
          .startOf('month')
          .format('x')
      } else {
        this.studyM = ''
      }
      this.getStudy()
    },
    getStudy() {
      questionRepo.getUserStudy(this.userId, this.studyM).then(res => {
        this.studyData = res.res.map(item => {
          item.showMore = false
          item.studyRow = []
          return item
        })
        this.$nextTick(() => {
          if (this.studyData.length !== 0) {
            let w = document.getElementsByClassName('timeCard')[0].clientWidth - 80
            this.cardW = w + 82
            this.rowNumber = parseInt(w / 200)
            this.rowRight = w - 200 * this.rowNumber
            this.studyData.forEach(item => {
              for (let i = 0; i < item.studies.length; i += this.rowNumber) {
                item.studyRow.push(item.studies.slice(i, i + this.rowNumber))
              }
            })
          }
        })
      })
    },
    formatSeconds(value) {
      let theTime = parseInt(value) // 需要转换的时间秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      let theTime3 = 0 // 天
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
          if (theTime2 > 24) {
            //大于24小时
            theTime3 = parseInt(theTime2 / 24)
            theTime2 = parseInt(theTime2 % 24)
          }
        }
      }
      let result = ''
      if (theTime > 0) {
        result = '' + parseInt(theTime) + '秒'
      }
      if (theTime1 > 0) {
        result = '' + parseInt(theTime1) + '分' + parseInt(theTime) + '秒'
      }
      if (theTime2 > 0) {
        result = '' + parseInt(theTime2) + '小时' + parseInt(theTime1) + '分'
      }
      if (theTime3 > 0) {
        result = '' + parseInt(theTime3) + '天' + parseInt(theTime2) + '小时'
      }
      return result
    }
  }
}
</script>

<style scoped lang="less">
.card {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  opacity: 1;
  border-radius: 3px;
  padding: 30px;
  text-align: left;
  .noRes {
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 40px 0;
  }
  .timeCardAct {
    background: rgba(248, 251, 255, 1) !important;
    border: 3px solid rgba(214, 230, 255, 1) !important;
    padding: 28px 38px 0 38px !important;
  }
  .timeCard {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(234, 237, 241, 1);
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    padding: 30px 40px 2px 40px;
    overflow: hidden;
    .timeItem {
      min-width: 188px;
      max-width: 200px;
      height: 101px;
      margin-bottom: 70px;
      .timeLine {
        display: flex;
        align-items: center;
        margin: 10px 0 6px 0;
        .lineDot {
          width: 16px;
          height: 16px;
          background: rgba(255, 255, 255, 1);
          border: 2px solid rgba(230, 230, 230, 1);
          border-radius: 50%;
        }
        .line {
          width: 100%;
          height: 2px;
          background: rgba(230, 230, 230, 1);
        }
      }
      .name {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        margin: 0 6px 0 6px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 114px;
        height: 21px;
        overflow: hidden;
      }
      .time {
        font-size: 12px;
        font-weight: 400;
        color: #888888;
      }
      .des {
        /*color: white;*/
        font-size: 12px;
        font-weight: 400;
      }
      .scoreG {
        font-size: 12px;
        font-weight: 400;
        color: rgba(90, 188, 80, 1);
      }
      .scoreR {
        font-size: 12px;
        font-weight: 400;
        color: rgba(230, 107, 66, 1);
      }
    }
    .more {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25px;
      background: linear-gradient(180deg, rgba(250, 250, 250, 1) 0%, rgba(240, 240, 240, 1) 100%);
      /*filter: blur(1px);*/
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .dot {
    width: 175px;
    display: flex;
    justify-content: space-between;
    .title {
      width: 136px;
      line-height: 30px;
      font-size: 14px;
      height: 30px;
      font-weight: 500;
      color: rgba(102, 102, 102, 1);
      text-align: center;
    }
    .time {
      font-size: 12px;
      font-weight: 400;
      color: rgba(160, 166, 173, 1);
      text-align: left;
    }
    .chooseTime {
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: rgba(86, 143, 235, 1);
    }
    .chooseT {
      width: 136px;
      text-align: center;
      padding: 0 10px;
      height: 30px;
      background: rgba(86, 143, 235, 1);
      box-shadow: 0px 1px 6px rgba(170, 202, 255, 1);
      border-radius: 3px;
      color: white;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .btn {
    width: 80px;
    height: 30px;
    background: rgba(230, 230, 230, 1);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    color: rgba(136, 136, 136, 1);
    text-align: center;
    margin: 0 10px;
    cursor: pointer;
    &:hover {
      background: rgba(214, 230, 255, 1);
      color: #568feb;
    }
  }
  .title {
    font-size: 16px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
  }
  .light {
    font-size: 14px;
    font-weight: 400;
    color: rgba(136, 136, 136, 1);
  }
}
.right {
  margin-left: 20px;
  flex: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep {
  .ivu-timeline-item-tail {
    left: 142px;
    top: -13px;
  }
  .ivu-timeline-item {
    padding: 0 0 5px 118px;
  }
  .ivu-timeline-item-content {
    top: -13px;
  }
}
::v-deep .ivu-radio-inner {
  &:after {
    background-color: rgba(86, 143, 235, 1) !important;
  }
}
.circleR {
  position: absolute;
  width: 50px;
  height: 173px;
  border: 2px solid #e6e6e6;
  border-top-left-radius: 0 50%;
  border-top-right-radius: 100% 50%;
  border-bottom-right-radius: 100% 50%;
  border-bottom-left-radius: 0 50%;
  border-left: none;
}
.circleL {
  position: absolute;
  width: 50px;
  height: 173px;
  border: 2px solid #e6e6e6;
  border-top-right-radius: 0 50%;
  border-top-left-radius: 100% 50%;
  border-bottom-left-radius: 100% 50%;
  border-bottom-right-radius: 0 50%;
  border-right: none;
}
</style>
